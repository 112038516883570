export const Header = () => import('../../components/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const HeaderSimple = () => import('../../components/HeaderSimple.vue' /* webpackChunkName: "components/header-simple" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const Sidebar = () => import('../../components/Sidebar.vue' /* webpackChunkName: "components/sidebar" */).then(c => wrapFunctional(c.default || c))
export const CategoryTable = () => import('../../components/category/CategoryTable.vue' /* webpackChunkName: "components/category-table" */).then(c => wrapFunctional(c.default || c))
export const CompanyTable = () => import('../../components/companies/CompanyTable.vue' /* webpackChunkName: "components/company-table" */).then(c => wrapFunctional(c.default || c))
export const EmployeeTable = () => import('../../components/employees/EmployeeTable.vue' /* webpackChunkName: "components/employee-table" */).then(c => wrapFunctional(c.default || c))
export const BaseTable = () => import('../../components/cards/BaseTable.vue' /* webpackChunkName: "components/base-table" */).then(c => wrapFunctional(c.default || c))
export const TableCard = () => import('../../components/cards/TableCard.vue' /* webpackChunkName: "components/table-card" */).then(c => wrapFunctional(c.default || c))
export const CardSelection = () => import('../../components/offers/CardSelection.vue' /* webpackChunkName: "components/card-selection" */).then(c => wrapFunctional(c.default || c))
export const CostTable = () => import('../../components/offers/CostTable.vue' /* webpackChunkName: "components/cost-table" */).then(c => wrapFunctional(c.default || c))
export const MaterialTable = () => import('../../components/offers/MaterialTable.vue' /* webpackChunkName: "components/material-table" */).then(c => wrapFunctional(c.default || c))
export const OfferCard = () => import('../../components/offers/OfferCard.vue' /* webpackChunkName: "components/offer-card" */).then(c => wrapFunctional(c.default || c))
export const OtherTable = () => import('../../components/offers/OtherTable.vue' /* webpackChunkName: "components/other-table" */).then(c => wrapFunctional(c.default || c))
export const PersonTable = () => import('../../components/offers/PersonTable.vue' /* webpackChunkName: "components/person-table" */).then(c => wrapFunctional(c.default || c))
export const AttachmentDialog = () => import('../../components/common/AttachmentDialog.vue' /* webpackChunkName: "components/attachment-dialog" */).then(c => wrapFunctional(c.default || c))
export const BooleanColumn = () => import('../../components/common/BooleanColumn.vue' /* webpackChunkName: "components/boolean-column" */).then(c => wrapFunctional(c.default || c))
export const ColorPicker = () => import('../../components/common/ColorPicker.vue' /* webpackChunkName: "components/color-picker" */).then(c => wrapFunctional(c.default || c))
export const ConfirmationDialog = () => import('../../components/common/ConfirmationDialog.vue' /* webpackChunkName: "components/confirmation-dialog" */).then(c => wrapFunctional(c.default || c))
export const DateTimeInOneInput = () => import('../../components/common/DateTimeInOneInput.vue' /* webpackChunkName: "components/date-time-in-one-input" */).then(c => wrapFunctional(c.default || c))
export const DateTimeWidget = () => import('../../components/common/DateTimeWidget.vue' /* webpackChunkName: "components/date-time-widget" */).then(c => wrapFunctional(c.default || c))
export const DateWidget = () => import('../../components/common/DateWidget.vue' /* webpackChunkName: "components/date-widget" */).then(c => wrapFunctional(c.default || c))
export const DateWidgetReturnsDateTime = () => import('../../components/common/DateWidgetReturnsDateTime.vue' /* webpackChunkName: "components/date-widget-returns-date-time" */).then(c => wrapFunctional(c.default || c))
export const FilesGallery = () => import('../../components/common/FilesGallery.vue' /* webpackChunkName: "components/files-gallery" */).then(c => wrapFunctional(c.default || c))
export const FormAutocomplete = () => import('../../components/common/FormAutocomplete.vue' /* webpackChunkName: "components/form-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const FormCombobox = () => import('../../components/common/FormCombobox.vue' /* webpackChunkName: "components/form-combobox" */).then(c => wrapFunctional(c.default || c))
export const FormFileInput = () => import('../../components/common/FormFileInput.vue' /* webpackChunkName: "components/form-file-input" */).then(c => wrapFunctional(c.default || c))
export const FormImage = () => import('../../components/common/FormImage.vue' /* webpackChunkName: "components/form-image" */).then(c => wrapFunctional(c.default || c))
export const FormInput = () => import('../../components/common/FormInput.vue' /* webpackChunkName: "components/form-input" */).then(c => wrapFunctional(c.default || c))
export const FormRichText = () => import('../../components/common/FormRichText.vue' /* webpackChunkName: "components/form-rich-text" */).then(c => wrapFunctional(c.default || c))
export const FormSelect = () => import('../../components/common/FormSelect.vue' /* webpackChunkName: "components/form-select" */).then(c => wrapFunctional(c.default || c))
export const FormSwitch = () => import('../../components/common/FormSwitch.vue' /* webpackChunkName: "components/form-switch" */).then(c => wrapFunctional(c.default || c))
export const FormTextarea = () => import('../../components/common/FormTextarea.vue' /* webpackChunkName: "components/form-textarea" */).then(c => wrapFunctional(c.default || c))
export const HeaderUserArea = () => import('../../components/common/HeaderUserArea.vue' /* webpackChunkName: "components/header-user-area" */).then(c => wrapFunctional(c.default || c))
export const Loader = () => import('../../components/common/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c))
export const PageTitle = () => import('../../components/common/PageTitle.vue' /* webpackChunkName: "components/page-title" */).then(c => wrapFunctional(c.default || c))
export const RollFields = () => import('../../components/common/RollFields.vue' /* webpackChunkName: "components/roll-fields" */).then(c => wrapFunctional(c.default || c))
export const SimpleRowTable = () => import('../../components/common/SimpleRowTable.vue' /* webpackChunkName: "components/simple-row-table" */).then(c => wrapFunctional(c.default || c))
export const PriceListTable = () => import('../../components/priceList/PriceListTable.vue' /* webpackChunkName: "components/price-list-table" */).then(c => wrapFunctional(c.default || c))
export const ChangePasswordForm = () => import('../../components/users/ChangePasswordForm.vue' /* webpackChunkName: "components/change-password-form" */).then(c => wrapFunctional(c.default || c))
export const UserForm = () => import('../../components/users/UserForm.vue' /* webpackChunkName: "components/user-form" */).then(c => wrapFunctional(c.default || c))
export const WorkerTable = () => import('../../components/workers/WorkerTable.vue' /* webpackChunkName: "components/worker-table" */).then(c => wrapFunctional(c.default || c))
export const CategoryForm = () => import('../../components/category/forms/CategoryForm.vue' /* webpackChunkName: "components/category-form" */).then(c => wrapFunctional(c.default || c))
export const CompanyForm = () => import('../../components/companies/forms/CompanyForm.vue' /* webpackChunkName: "components/company-form" */).then(c => wrapFunctional(c.default || c))
export const EmployeeForm = () => import('../../components/employees/forms/EmployeeForm.vue' /* webpackChunkName: "components/employee-form" */).then(c => wrapFunctional(c.default || c))
export const CardForm = () => import('../../components/cards/form/CardForm.vue' /* webpackChunkName: "components/card-form" */).then(c => wrapFunctional(c.default || c))
export const CostForm = () => import('../../components/cards/form/CostForm.vue' /* webpackChunkName: "components/cost-form" */).then(c => wrapFunctional(c.default || c))
export const MaterialForm = () => import('../../components/cards/form/MaterialForm.vue' /* webpackChunkName: "components/material-form" */).then(c => wrapFunctional(c.default || c))
export const OtherForm = () => import('../../components/cards/form/OtherForm.vue' /* webpackChunkName: "components/other-form" */).then(c => wrapFunctional(c.default || c))
export const PersonalForm = () => import('../../components/cards/form/PersonalForm.vue' /* webpackChunkName: "components/personal-form" */).then(c => wrapFunctional(c.default || c))
export const OrderArrows = () => import('../../components/cards/widgets/OrderArrows.vue' /* webpackChunkName: "components/order-arrows" */).then(c => wrapFunctional(c.default || c))
export const MultiPrintingSetForm = () => import('../../components/offers/forms/MultiPrintingSetForm.vue' /* webpackChunkName: "components/multi-printing-set-form" */).then(c => wrapFunctional(c.default || c))
export const OfferForm = () => import('../../components/offers/forms/OfferForm.vue' /* webpackChunkName: "components/offer-form" */).then(c => wrapFunctional(c.default || c))
export const PrintingSetForm = () => import('../../components/offers/forms/PrintingSetForm.vue' /* webpackChunkName: "components/printing-set-form" */).then(c => wrapFunctional(c.default || c))
export const TitleForm = () => import('../../components/offers/forms/TitleForm.vue' /* webpackChunkName: "components/title-form" */).then(c => wrapFunctional(c.default || c))
export const AddCardOffer = () => import('../../components/offers/widgets/AddCardOffer.vue' /* webpackChunkName: "components/add-card-offer" */).then(c => wrapFunctional(c.default || c))
export const AddCardOfferInline = () => import('../../components/offers/widgets/AddCardOfferInline.vue' /* webpackChunkName: "components/add-card-offer-inline" */).then(c => wrapFunctional(c.default || c))
export const CommentOfferDialog = () => import('../../components/offers/widgets/CommentOfferDialog.vue' /* webpackChunkName: "components/comment-offer-dialog" */).then(c => wrapFunctional(c.default || c))
export const EditOfferDialog = () => import('../../components/offers/widgets/EditOfferDialog.vue' /* webpackChunkName: "components/edit-offer-dialog" */).then(c => wrapFunctional(c.default || c))
export const HotelDiscountDialog = () => import('../../components/offers/widgets/HotelDiscountDialog.vue' /* webpackChunkName: "components/hotel-discount-dialog" */).then(c => wrapFunctional(c.default || c))
export const PrintMultipleOffersDialog = () => import('../../components/offers/widgets/PrintMultipleOffersDialog.vue' /* webpackChunkName: "components/print-multiple-offers-dialog" */).then(c => wrapFunctional(c.default || c))
export const PrintOfferDialog = () => import('../../components/offers/widgets/PrintOfferDialog.vue' /* webpackChunkName: "components/print-offer-dialog" */).then(c => wrapFunctional(c.default || c))
export const PrintPricingProductDialog = () => import('../../components/offers/widgets/PrintPricingProductDialog.vue' /* webpackChunkName: "components/print-pricing-product-dialog" */).then(c => wrapFunctional(c.default || c))
export const RelativeDiscountDialog = () => import('../../components/offers/widgets/RelativeDiscountDialog.vue' /* webpackChunkName: "components/relative-discount-dialog" */).then(c => wrapFunctional(c.default || c))
export const PriceListItemForm = () => import('../../components/priceList/forms/PriceListItemForm.vue' /* webpackChunkName: "components/price-list-item-form" */).then(c => wrapFunctional(c.default || c))
export const PrintingPricingSetForm = () => import('../../components/priceList/forms/PrintingPricingSetForm.vue' /* webpackChunkName: "components/printing-pricing-set-form" */).then(c => wrapFunctional(c.default || c))
export const WorkerForm = () => import('../../components/workers/forms/WorkerForm.vue' /* webpackChunkName: "components/worker-form" */).then(c => wrapFunctional(c.default || c))
export const OfferDiscussion = () => import('../../components/offers/widgets/discussion/OfferDiscussion.vue' /* webpackChunkName: "components/offer-discussion" */).then(c => wrapFunctional(c.default || c))
export const OfferDiscussionForm = () => import('../../components/offers/widgets/discussion/OfferDiscussionForm.vue' /* webpackChunkName: "components/offer-discussion-form" */).then(c => wrapFunctional(c.default || c))
export const OfferDiscussionMessage = () => import('../../components/offers/widgets/discussion/OfferDiscussionMessage.vue' /* webpackChunkName: "components/offer-discussion-message" */).then(c => wrapFunctional(c.default || c))
export const OfferDiscussionThread = () => import('../../components/offers/widgets/discussion/OfferDiscussionThread.vue' /* webpackChunkName: "components/offer-discussion-thread" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
